@font-face {
  font-family: "Inter";
  src: url("../fonts/subset-Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/subset-Inter-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/subset-Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/subset-Inter-ExtraBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/subset-Inter-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?gho2zw");
  src:
    url("../fonts/icomoon.eot?gho2zw#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?gho2zw") format("truetype"),
    url("../fonts/icomoon.woff?gho2zw") format("woff"),
    url("../fonts/icomoon.svg?gho2zw#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "willow-filled";
  src: url("../fonts/willow-filled.eot?j63inl");
  src:
    url("../fonts/willow-filled.eot?j63inl#iefix") format("embedded-opentype"),
    url("../fonts/willow-filled.ttf?j63inl") format("truetype"),
    url("../fonts/willow-filled.woff?j63inl") format("woff"),
    url("../fonts/willow-filled.svg?j63inl#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="header-icon-"],
[class*=" header-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "willow-filled" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header-icon-ads:before {
  content: "\e900";
}
.header-icon-analytics:before {
  content: "\e901";
}
.header-icon-calendar:before {
  content: "\e902";
}
.header-icon-homepage:before {
  content: "\e903";
}
.header-icon-ideas:before {
  content: "\e904";
}
.header-icon-news:before {
  content: "\e905";
}

.icon-combined-content:before {
  content: "\e975";
}
.icon-combined-social-media:before {
  content: "\e976";
}
.icon-combined-stats:before {
  content: "\e977";
}
.icon-audience-filled:before {
  content: "\e966";
}
.icon-clock-filled:before {
  content: "\e967";
}
.icon-analytics-comment:before {
  content: "\e968";
  color: #808082;
}
.icon-analytics-likes .path1:before {
  content: "\e969";
  color: rgb(255, 14, 14);
}
.icon-analytics-likes .path2:before {
  content: "\e96a";
  margin-left: -1.5em;
  color: rgb(0, 99, 251);
}
.icon-analytics-likes .path3:before {
  content: "\e96b";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-compass-filled:before {
  content: "\e96c";
}
.icon-plus-rounded-filled:before {
  content: "\e96d";
}
.icon-plus-rounded:before {
  content: "\e96e";
}
.icon-analytics-share:before {
  content: "\e96f";
  color: #808082;
}
.icon-timeline-filled:before {
  content: "\e970";
}
.header-icon-link:before {
  content: "\e9cb";
}
.icon-timeline:before {
  content: "\e971";
}
.icon-play-button .path1:before {
  content: "\e972";
  color: rgb(255, 255, 255);
}
.icon-play-button .path2:before {
  content: "\e973";
  margin-left: -1.03125em;
  color: rgb(28, 30, 31);
  opacity: 0.66;
}
.icon-partial-match:before {
  content: "\e965";
}
.icon-info-20:before {
  content: "\e964";
  color: #646769;
}
.icon-sparkle:before {
  content: "\e963";
}
.icon-text:before {
  content: "\e962";
}
.icon-language:before {
  content: "\e961";
}
.icon-bookmark-filled-20:before {
  content: "\e95f";
}
.icon-bookmark-filled-24:before {
  content: "\e960";
}
.icon-bookmark-20:before {
  content: "\e955";
}
.icon-bookmark-24:before {
  content: "\e956";
}
.icon-like-filled-24:before {
  content: "\e957";
}
.icon-like-filled-20:before {
  content: "\e958";
}
.icon-disliked-20:before {
  content: "\e959";
}
.icon-disliked-24:before {
  content: "\e95a";
}
.icon-like-24:before {
  content: "\e95b";
}
.icon-like-20:before {
  content: "\e95c";
}
.icon-disliked-filled-20:before {
  content: "\e95d";
}
.icon-disliked-filled-24:before {
  content: "\e95e";
}
.icon-pdf:before {
  content: "\e978";
}
.icon-pdf-filled:before {
  content: "\e979";
}
.icon-read:before {
  content: "\e954";
}
.icon-fat-bookmark:before {
  content: "\e952";
  color: #646769;
}
.icon-block-collapse:before {
  content: "\e953";
}
.icon-tag:before {
  content: "\e951";
}
.icon-list-filled:before {
  content: "\e94d";
}
.icon-search-filled-dark:before {
  content: "\e94e";
}
.icon-source:before {
  content: "\e94f";
}
.icon-tag-filled:before {
  content: "\e950";
}
.icon-collapse:before {
  content: "\e94b";
}
.icon-list:before {
  content: "\e94c";
}
.icon-ungroup:before {
  content: "\e94a";
}
.icon-published:before {
  content: "\e949";
}
.icon-task-circle:before {
  content: "\e939";
}
.icon-draft-clock:before {
  content: "\e946";
}
.icon-draft:before {
  content: "\e947";
}
.icon-task-circular:before {
  content: "\e944";
}
.icon-autopilot:before {
  content: "\e93d";
}
.icon-edit-series:before {
  content: "\e93b";
}
.icon-write:before {
  content: "\e93e";
}
.icon-return:before {
  content: "\e938";
}
.icon-download-lg:before {
  content: "\e935";
}
.icon-call-to-action:before {
  content: "\e92d";
}
.icon-user:before {
  content: "\e933";
}
.icon-notification:before {
  content: "\e925";
}
.icon-support-filled:before {
  content: "\e93c";
}
.icon-settings-filled:before {
  content: "\e93f";
}
.icon-calendar-filled:before {
  content: "\e92a";
}
.icon-send-filled:before {
  content: "\e92b";
}
.icon-search-filled:before {
  content: "\e92c";
}
.icon-bookmark-filled:before {
  content: "\e92e";
}
.icon-bookmark:before {
  content: "\e92f";
  color: #646769;
}
.icon-image-filled:before {
  content: "\e930";
}
.icon-location1:before {
  content: "\e931";
}
.icon-video-filled:before {
  content: "\e934";
}
.icon-eye-filled:before {
  content: "\e936";
}
.icon-support:before {
  content: "\e93a";
}
.icon-audience:before {
  content: "\e900";
}
.icon-newaudience:before {
  content: "\e901";
}
.icon-error-circle:before {
  content: "\e974";
}
.icon-draft-filled:before {
  content: "\e948";
}
.icon-heart-filled:before {
  content: "\e943";
}
.icon-error:before {
  content: "\e940";
  color: #ff0e0e;
}
.icon-task-check .path1:before {
  content: "\e941";
  color: rgb(255, 255, 255);
}
.icon-task-check .path2:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(28, 30, 31);
}
.icon-check:before {
  content: "\e937";
}
.icon-cancel:before {
  content: "\e932";
}
.icon-eye-hidden:before {
  content: "\e929";
}
.icon-image:before {
  content: "\e902";
}
.icon-location:before {
  content: "\e903";
}
.icon-text-filled:before {
  content: "\e904";
}
.icon-video:before {
  content: "\e905";
}
.icon-impressions:before {
  content: "\e945";
}
.icon-clock:before {
  content: "\e926";
}
.icon-repeat:before {
  content: "\e927";
}
.icon-task:before {
  content: "\e928";
}
.icon-copy:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e907";
}
.icon-external:before {
  content: "\e908";
}
.icon-arrowleft:before {
  content: "\e909";
}
.icon-arrowright:before {
  content: "\e90a";
}
.icon-at:before {
  content: "\e90b";
}
.icon-billing:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-chat:before {
  content: "\e90e";
}
.icon-clicks:before {
  content: "\e90f";
}
.icon-comment:before {
  content: "\e910";
}
.icon-delete:before {
  content: "\e911";
}
.icon-dots:before {
  content: "\e912";
}
.icon-download:before {
  content: "\e913";
}
.icon-eye:before {
  content: "\e914";
}
.icon-filter:before {
  content: "\e915";
}
.icon-hashtag:before {
  content: "\e916";
}
.icon-help:before {
  content: "\e917";
}
.icon-like:before {
  content: "\e918";
}
.icon-plus:before {
  content: "\e919";
}
.icon-present:before {
  content: "\e91a";
}
.icon-search:before {
  content: "\e91b";
}
.icon-security:before {
  content: "\e91c";
}
.icon-select:before {
  content: "\e91d";
}
.icon-send:before {
  content: "\e91e";
}
.icon-settings:before {
  content: "\e91f";
}
.icon-share:before {
  content: "\e920";
}
.icon-signout:before {
  content: "\e921";
}
.icon-smiley:before {
  content: "\e922";
}
.icon-swap:before {
  content: "\e923";
}
.icon-team:before {
  content: "\e924";
}
.icon-whatsapp:before {
  content: "\ea93";
}
