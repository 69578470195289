.react-datepicker__input-container input[type="text"] {
  border-radius: 8px;
  backgound: #eff2f3;
  font-size: 12px;
  color: #494949;
  padding: 12px;
  border: none;
  max-width: 136px;
}

.time-input {
  max-width: 33px !important;
}

.planner-time-input {
  max-width: 50px !important;
}

.one-off-date .react-datepicker__triangle {
  left: 190px;
}

.one-off-date-input {
  max-width: 190px !important;
  width: 190px;
  font-size: 14px !important;
}

.planner-date-range-input {
  min-width: 72px;
  width: 100px;
  font-size: 14px !important;
  color: #8d9497 !important;
}

.planner-date-range-input.modified {
  color: #494949 !important;
}

.react-datepicker__close-icon::after {
  background: transparent;
  color: #8d9497;
}

.react-datepicker__close-icon::after {
  background: transparent;
  color: #646769;
}

.react-datepicker__close-icon:hover::after {
  background: rgba(102, 119, 128, 0.16);
  color: #646769;
}
