/* Sadly, it was necessary to copy all css from the emoji plugin below because it was impossible to personalize otherwise */

.emojiSelect {
  display: inline-block;
}

.emojiSelectButton,
.emojiSelectButtonPressed {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  opacity: 0.01;
}

.emojiSelectButton:focus,
.emojiSelectButtonPressed:focus {
  outline: 0;
  /* reset for :focus */
}

.emojiSelectButton:hover,
.emojiSelectButtonPressed:hover {
  background: #f3f3f3;
}

.emojiSelectButton:active,
.emojiSelectButtonPressed:active {
  background: #e6e6e6;
}

.emojiSelectButtonPressed {
  background: #ededed;
}

.emojiSelectPopover {
  margin-top: 10px;
  padding: 0 0.3em;
  position: fixed;
  z-index: 11;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
}

.emojiSelectPopoverClosed {
  display: none;
}

.emojiSelectPopoverTitle {
  margin: 0 0 0.3em;
  padding-left: 1em;
  height: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.emojiSelectPopoverGroups {
  margin: 0 0 0.3em;
  position: relative;
  z-index: 0;
  width: 21em;
  height: 20em;
}

.emojiSelectPopoverGroup {
  padding: 0 0.5em;
}

.emojiSelectPopoverGroup:first-child .emojiSelectPopoverGroupTitle {
  display: none;
}

.emojiSelectPopoverGroupTitle {
  margin: 1em 0;
  padding-left: 0.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.emojiSelectPopoverGroupList {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.emojiSelectPopoverGroupItem {
  width: 2.5em;
  height: 2.5em;
}

.emojiSelectPopoverToneSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.emojiSelectPopoverToneSelectList {
  margin: 0.3em;
  padding: 0.3em;
  position: absolute;
  display: -webkit-box;
  display: flex;
  list-style: none;
  border: 1px solid #e0e0e0;
  border-radius: 0.5em;
  background: #fff;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
}

.emojiSelectPopoverToneSelectItem {
  width: 2.5em;
  height: 2.5em;
}

.emojiSelectPopoverToneSelectItem:first-child {
  border-right: 1px solid #e0e0e0;
}

.emojiSelectPopoverEntry,
.emojiSelectPopoverEntryFocused {
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  font-size: 20px;
}

.emojiSelectPopoverEntryFocused {
  background-color: #efefef;
}

.emojiSelectPopoverEntryIcon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.emojiSelectPopoverNav {
  margin: 0;
  padding: 0 0.5em;
  display: -webkit-box;
  display: flex;
  width: 20em;
  list-style: none;
}

.emojiSelectPopoverNavItem {
  width: 2.5em;
  height: 2.5em;
}

.emojiSelectPopoverNavEntry,
.emojiSelectPopoverNavEntryActive {
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  color: #bdbdbd;
  background: none;
  border: none;
  outline: none;
}

.emojiSelectPopoverNavEntryActive {
  color: #42a5f5;
}

.emojiSelectPopoverScrollbar {
  position: absolute;
  right: 0;
  top: 0.3em;
  bottom: 0.3em;
  width: 0.25em;
  background-color: #e0e0e0;
  border-radius: 0.125em;
  opacity: 0.1;
  transition: opacity 0.4s;
}

.emojiSelectPopoverScrollbarThumb {
  background-color: #000;
  border-radius: 0.125em;
  cursor: pointer;
}

.emojiSelectPopoverGroups:hover .emojiSelectPopoverScrollbar {
  opacity: 0.3;
}

.emojiSelectPopoverGroups .emojiSelectPopoverScrollbar:hover,
.emojiSelectPopoverGroups .emojiSelectPopoverScrollbar:active {
  opacity: 0.6;
}

.emoji {
  background-position: center;
  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-size: contain;
  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;

  /*
     We need to limit the emoji width because it can be multiple characters
     long if it is a 32bit unicode. Since the proper width depends on the font and
     it's relationship between 0 and other characters it's not ideal. 1.95ch is not
     the best value, but hopefully a good enough approximation for most fonts.
     */
  display: inline-block;
  overflow: hidden;
  max-width: 1.95ch;
  /*
     Needed for iOS rendering to avoid some icons using a lot of height without
     actually needing it.
     */
  max-height: 1em;
  line-height: inherit;
  margin: -0.2ex 0em 0.2ex;
  /*
     In the past we used opacity: 0 to hide the original Emoji icon no matter what
     system it is. Recently we switched to color: transparent since it started to
     work in recent iOS version.
     */
  color: transparent;

  /*
     Some SVG files (say 2764 for :heart:) don't have default width/height, thus
     may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
     Chrome 61).
     */
  min-width: 1em;
}

.emojiSuggestionsEntry {
  padding: 5px 10px 1px 10px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.emojiSuggestionsEntry:active {
  background-color: #cce7ff;
}

.emojiSuggestionsEntryFocused {
  background-color: #e6f3ff;
}

.emojiSuggestionsEntryText {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
}

.emojiSuggestionsEntryIcon {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: inline-block;
}

.emojiSuggestions {
  border: 1px solid #eee;
  margin-top: 1.75em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.editorHashtag,
.socialMention {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
}

.socialMention {
  color: #0063fb;
}

.socialMentionSuggestions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
  max-height: 327px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid rgba(102, 119, 128, 0.16);
  box-sizing: border-box;
  box-shadow:
    0px 8px 16px -8px rgba(28, 30, 31, 0.12),
    0px 18px 27px -5px rgba(28, 30, 31, 0.15),
    0px 0px 0px 1px rgba(28, 30, 31, 0.05);
  border-radius: 12px;
  z-index: 99;
  top: 7px !important;
  left: 1px !important;
}

.socialMentionSuggestions > div {
  width: 100%;
}

.socialMentionSuggestions::-webkit-scrollbar {
  width: 0;
}
