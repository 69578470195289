.suggestions-enter {
  transform: scale(0.3);
  opacity: 0.01;
}

.suggestions-enter.suggestions-enter-active {
  transform: scale(1);
  opacity: 1;
  transition:
    opacity 400ms 600ms ease-in,
    transform 400ms 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.contentTypesForm-enter {
  transform: translate3d(433px, 0, 0);
}

.contentTypesForm-enter.contentTypesForm-enter-active {
  transform: translate3d(0, 0, 0);
  transition: transform 250ms ease-in-out;
}

.contentTypesForm-leave {
  transform: translate3d(0, 0, 0);
}

.contentTypesForm-leave.contentTypesForm-leave-active {
  transform: translate3d(433px, 0, 0);
  transition: transform 250ms ease-in-out;
}

.contentTypesFormReversed-enter {
  transform: translate3d(-433px, 0, 0);
}

.contentTypesFormReversed-enter.contentTypesFormReversed-enter-active {
  transform: translate3d(0, 0, 0);
  transition: transform 250ms ease-in-out;
}

.contentTypesFormReversed-leave {
  transform: translate3d(0, 0, 0);
}

.contentTypesFormReversed-leave.contentTypesFormReversed-leave-active {
  transform: translate3d(-433px, 0, 0);
  transition: transform 250ms ease-in-out;
}

.formBottom-enter {
  opacity: 0;
}

.formBottom-enter.formBottom-enter-active {
  opacity: 1;
  transition: opacity 1000ms 500ms;
}

.loadingSlots-enter {
  opacity: 0;
}

.loadingSlots-enter.loadingSlots-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.loadingSlots-leave {
  opacity: 1;
}

.loadingSlots-leave.loadingSlots-leave-active {
  opacity: 0;
  transition: opacity 250ms;
}

.fade-enter .form-box {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .form-box {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .form-box {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .form-box {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active .form-box,
.fade-exit-active .form-box {
  transition:
    opacity 500ms,
    transform 500ms;
}
