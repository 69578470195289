.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #0d5eff;
}
.mdc-text-field--focused
  .mdc-text-field__input:required
  ~ .mdc-floating-label::after,
.mdc-text-field--focused
  .mdc-text-field__input:required
  ~ .mdc-notched-outline
  .mdc-floating-label::after {
  color: #0d5eff;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: #0d5eff;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  bottom: auto;
  top: 22px;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #e65050;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #e65050;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(
    .mdc-text-field--disabled
  ):not(.mdc-text-field--focused)
  .mdc-text-field__input:hover
  ~ .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(
    .mdc-text-field--disabled
  ):not(.mdc-text-field--focused)
  .mdc-text-field__input:hover
  ~ .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(
    .mdc-text-field--disabled
  ):not(.mdc-text-field--focused)
  .mdc-text-field__input:hover
  ~ .mdc-notched-outline
  .mdc-notched-outline__trailing,
.mdc-text-field--outlined.mdc-text-field--invalid:not(
    .mdc-text-field--disabled
  ):not(.mdc-text-field--focused)
  .mdc-text-field__icon:hover
  ~ .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(
    .mdc-text-field--disabled
  ):not(.mdc-text-field--focused)
  .mdc-text-field__icon:hover
  ~ .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(
    .mdc-text-field--disabled
  ):not(.mdc-text-field--focused)
  .mdc-text-field__icon:hover
  ~ .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: #e65050;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(
    .mdc-text-field--disabled
  ).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(
    .mdc-text-field--disabled
  ).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(
    .mdc-text-field--disabled
  ).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: #e65050;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background {
  border-color: #0061ff;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #0061ff);
  background-color: #0061ff;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #0061ff);
}

.mdc-checkbox__background::before {
  background-color: #0061ff;
}

.mdc-checkbox::before,
.mdc-checkbox::after {
  background-color: #0061ff;
}
